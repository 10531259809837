import {
  CREDENTIALS_FETCH_BEGIN,
  CREDENTIALS_FETCH_SUCCESS,
  CREDENTIALS_FETCH_FAILURE,
  SUBJECT_FETCH_BEGIN,
  SUBJECT_FETCH_FAILURE,
  SUBJECT_FETCH_SUCCESS,
  GET_MORE_SUBJECT_BEGIN,
  GET_MORE_SUBJECT_SUCCESS,
  GET_MORE_SUBJECT_FAILURE,
} from "./dashBoard.constant";

const initState = {
  credentialsStats: {
    loading: false,
    data: {},
    error: "",
  },
  subjectStats: {
    loading: false,
    data: {},
    error: "",
  },
};

const dashBoardReducer = (state = initState, action) => {
  switch (action.type) {
    case CREDENTIALS_FETCH_BEGIN:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: true,
          error: "",
        },
      };
    case CREDENTIALS_FETCH_SUCCESS:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case CREDENTIALS_FETCH_FAILURE:
      return {
        ...state,
        credentialsStats: {
          ...state.credentialsStats,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case SUBJECT_FETCH_BEGIN:
      return {
        ...state,
        subjectStats: {
          ...state.subjectStats,
          loading: true,
          error: "",
          data: {},
        },
      };
    case SUBJECT_FETCH_SUCCESS:
      return {
        ...state,
        subjectStats: {
          ...state.subjectStats,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case SUBJECT_FETCH_FAILURE:
      return {
        ...state,
        subjectStats: {
          ...state.subjectStats,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case GET_MORE_SUBJECT_BEGIN:
      return {
        ...state,
        subjectStats: {
          ...state.subjectStats,
          error: "",
          loading: true,
        },
      };
    case GET_MORE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjectStats: {
          error: "",
          loading: false,
          data: {
            ...state.subjectStats.data,
            data: [...state.subjectStats.data.data, ...action.payload],
          },
        },
      };
    case GET_MORE_SUBJECT_FAILURE:
      return {
        ...state,
        subjectStats: {
          error: action.payload,
          loading: false,
          data: state.subjectStats.data,
        },
      };
    default:
      return state;
  }
};

export default dashBoardReducer;
